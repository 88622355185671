





















































































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import { customerModule } from '@/store/modules/customer';
import { ubigeoModule } from '@/store/modules/ubigeo';
import FormCustomer from '@/components/FormCustomer.vue';
import { Customer } from '@/interfaces/customer';

@Component({
    components: { FormCustomer }
})
export default class CustomerRegister extends Vue {
    form: Partial<Customer> & {
        emptyEmail?: boolean;
    } = {};
    @Ref() formCustomer!: FormCustomer;

    async store() {
        try {
            switch (this.$route.name) {
                case 'CustomerRegister':
                    await customerModule.customerRegister(this.form);
                    this.formCustomer.reset();
                    this.$confirm(
                        'Has registrado al prospecto principal ¿Deseas Registar al prospecto asociado?',
                        ' Muy Bien',
                        {
                            confirmButtonText:
                                'Si, deseo registrar al prospecto asociado',
                            cancelButtonText: 'No, lo haré en otro momento',
                            type: 'success',
                            customClass: 'success-customer'
                        }
                    ).then(() => {
                        if (customerModule.selectedCustomer) {
                            this.$router.push({
                                name: 'CustomerAssociateRegister',
                                params: {
                                    id: customerModule.selectedCustomer._id
                                }
                            });
                        }
                    });
                    break;
                case 'CustomerEdit':
                    await customerModule.update(this.form);
                    this.$notify.success({
                        title: 'Prospecto editado!',
                        message: 'Se ha editado el prospecto con éxito'
                    });
                    break;
                case 'CustomerAssociateRegister':
                    await customerModule.storeAssociated(this.form);
                    this.$notify.success({
                        title: 'Prospecto asociado registrado!',
                        message:
                            'Se ha agregado al prospecto asociado con éxito'
                    });
                    this.$router.push({
                        name: 'CustomerAssociateEdit',
                        params: { id: this.$route.params.id }
                    });
                    break;
                case 'CustomerAssociateEdit':
                    await customerModule.updateAssociated(this.form);
                    this.$notify.success({
                        title: 'Prospecto asociado editado!',
                        message: 'Se ha editado al prospecto asociado con éxito'
                    });
                    break;
            }
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }

    get hasAssociate() {
        return customerModule.customer?.associates;
    }

    get isEdit() {
        return this.$route.params.id;
    }

    get showAssociate() {
        return ['CustomerAssociateRegister', 'CustomerAssociateEdit'].includes(
            this.$route.name || ''
        );
    }

    addAssociate() {
        if (this.hasAssociate) {
            this.$router.push({
                name: 'CustomerAssociateEdit',
                params: { id: this.$route.params.id }
            });
        } else {
            this.$router.push({
                name: 'CustomerAssociateRegister',
                params: { id: this.$route.params.id }
            });
        }
    }

    @Watch('$route.params.id', {
        deep: true,
        immediate: true
    })
    async handleRoute(id: string) {
        if (this.formCustomer) {
            this.formCustomer.reset();
        }
        if (this.isEdit && !this.showAssociate) {
            await customerModule.show(id);
            await this.setForm(customerModule.customer as Customer);
        } else if (this.$route.name === 'CustomerAssociateEdit') {
            this.form = { parentId: id };
            await customerModule.getAssociateByParentId(id);
            if (customerModule.associate) {
                await this.setForm(customerModule.associate as Customer);
            }
        } else if (this.$route.name === 'CustomerAssociateRegister') {
            this.form = { parentId: id, type: 'secondary' };
        }
    }

    async setForm(user: Partial<Customer>) {
        this.form = {
            _id: user._id,
            email: user.email,
            name: user.name,
            firstSurname: user.firstSurname,
            secondSurname: user.secondSurname,
            documentType: user.documentType,
            documentNumber: user.documentNumber,
            phone: user.phone,
            anexo: user.anexo,
            cellphone: user.cellphone,
            sex: user.sex,
            parentId: user.parentId,
            departmentId: user.departmentId,
            provinceId: user.provinceId,
            districtId: user.districtId,
            urbanizationId: user.urbanizationId,
            address: user.address,
            interior: user.interior,
            maritalStatus: user.maritalStatus,
            emptyEmail: !user.email
        };

        if (this.form.departmentId) {
            await ubigeoModule.getProvinces(this.form.departmentId);
        }

        if (this.form.provinceId) {
            await ubigeoModule.getDistricts(this.form.provinceId);
        }

        if (this.form.districtId) {
            await ubigeoModule.getUrbanizations(this.form.districtId);
        }
    }
}
